var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.seckillInfo.length)?_c('div',{attrs:{"id":"seckill"}},[_c('van-tabs',{attrs:{"ellipsis":false,"background":"transparent","title-inactive-color":"#fff","title-active-color":_vm.seckillInfo[_vm.seckillIndex].seckillEnableState === 2 ? '#fff' : '#f91c12'},on:{"change":_vm.tabChange},model:{value:(_vm.seckillIndex),callback:function ($$v) {_vm.seckillIndex=$$v},expression:"seckillIndex"}},_vm._l((_vm.seckillInfo),function(item,index){return _c('van-tab',{key:index,attrs:{"title-style":_vm.seckillIndex === index &&
        _vm.seckillInfo[_vm.seckillIndex].seckillEnableState === 2
          ? 'background: #ff817b;'
          : ''},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm._f("day")(item.time)))]),_c('h3',[_vm._v(_vm._s(_vm._f("time")(item.seckillStartTime)))]),_c('b',[_vm._v(" "+_vm._s(item.seckillEnableState ? item.seckillEnableState === 1 ? "火热抢购中" : "已结束" : "即将开始")+" ")])]},proxy:true}],null,true)})}),1),_c('div',{staticClass:"seckill_content"},[_c('div',{staticClass:"end_countdown"},[(_vm.seckillInfo[_vm.seckillIndex].seckillEnableState !== 2)?_c('p',[_vm._v(" 距离本场"+_vm._s(_vm.seckillInfo[_vm.seckillIndex].seckillEnableState ? "结束" : "开始")+" "),_c('van-count-down',{attrs:{"time":_vm.seckillInfo[_vm.seckillIndex].seckillEnableState
              ? _vm.seckillInfo[_vm.seckillIndex].seckillEndTime - new Date()
              : _vm.seckillInfo[_vm.seckillIndex].seckillStartTime - new Date()},on:{"finish":_vm.finish},scopedSlots:_vm._u([{key:"default",fn:function(timeData){return [_c('span',{staticClass:"block"},[_vm._v(_vm._s(timeData.days ? timeData.hours + timeData.days * 24 : timeData.hours < 10 ? "0" + timeData.hours : timeData.hours))]),_c('span',{staticClass:"colon"},[_vm._v(":")]),_c('span',{staticClass:"block"},[_vm._v(_vm._s(timeData.minutes < 10 ? "0" + timeData.minutes : timeData.minutes))]),_c('span',{staticClass:"colon"},[_vm._v(":")]),_c('span',{staticClass:"block"},[_vm._v(_vm._s(timeData.seconds < 10 ? "0" + timeData.seconds : timeData.seconds))])]}}],null,false,397035129)})],1):_vm._e(),_c('p',[_vm._v("秒杀结果以支付时间为准，抢完即止，结束后恢复原售价")])]),_vm._l((_vm.goodsLsit),function(item,i){return _c('div',{key:i,staticClass:"goods_box",on:{"click":function($event){_vm.seckillInfo[_vm.seckillIndex].seckillEnableState !== 2
          ? _vm.$router.push({
              path: 'goodsDetails',
              query: {
                commId: item.commId,
              },
            })
          : ''}}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item.filePath),expression:"item.filePath"}]}),_c('div',{staticClass:"details"},[_c('p',{staticClass:"name"},[_vm._v(_vm._s(item.commodityName))]),_c('div',{staticClass:"inventory"},[_c('div',{staticClass:"progress_bar",style:({
              '--barWidth':
                Math.round((item.restAccount / item.inventoryCount) * 100) +
                '%',
            })},[_c('span',[_vm._v("库存"+_vm._s(Math.round((item.restAccount / item.inventoryCount) * 100))+"%")])]),_c('span',[_vm._v("限购"+_vm._s(item.buyCount)+"件")])]),_c('span',{staticClass:"price_difference"},[_vm._v("立省"+_vm._s(Math.round((item.retailPrice - item.retailSeckillPrice) * 100) / 100)+"元")]),_c('div',{staticClass:"price"},[_c('div',{staticClass:"left"},[_c('p',[_vm._v("￥"+_vm._s(item.retailPrice.toFixed(2)))]),_c('p',[_c('span',[_vm._v("￥")]),_vm._v(_vm._s(item.retailSeckillPrice.toFixed(2)))])]),_c('button',{style:(_vm.seckillInfo[_vm.seckillIndex].seckillEnableState === 0
                ? 'background:#F9635C'
                : _vm.seckillInfo[_vm.seckillIndex].seckillEnableState === 2 ||
                  (_vm.seckillInfo[_vm.seckillIndex].seckillEnableState === 1 &&
                    item.restAccount === 0)
                ? 'background:#C9C9C9'
                : '')},[_vm._v(" "+_vm._s(_vm.seckillInfo[_vm.seckillIndex].seckillEnableState ? _vm.seckillInfo[_vm.seckillIndex].seckillEnableState === 1 ? item.restAccount ? "立即抢购" : "已售罄" : "已结束" : "未开始")+" ")])])])])}),_c('van-empty',{directives:[{name:"show",rawName:"v-show",value:(!_vm.goodsLsit.length),expression:"!goodsLsit.length"}],attrs:{"description":"暂无商品"}})],2),_c('functionButton')],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }