<template>
  <div id="seckill" v-if="seckillInfo.length">
    <van-tabs
      :ellipsis="false"
      background="transparent"
      title-inactive-color="#fff"
      :title-active-color="
        seckillInfo[seckillIndex].seckillEnableState === 2 ? '#fff' : '#f91c12'
      "
      v-model="seckillIndex"
      @change="tabChange"
    >
      <van-tab
        v-for="(item, index) in seckillInfo"
        :key="index"
        :title-style="
          seckillIndex === index &&
          seckillInfo[seckillIndex].seckillEnableState === 2
            ? 'background: #ff817b;'
            : ''
        "
      >
        <template #title>
          <span>{{ item.time | day }}</span>
          <h3>{{ item.seckillStartTime | time }}</h3>
          <b>
            {{
              item.seckillEnableState
                ? item.seckillEnableState === 1
                  ? "火热抢购中"
                  : "已结束"
                : "即将开始"
            }}
          </b>
        </template>
      </van-tab>
    </van-tabs>
    <!-- <div class="seckill_times">
      <ul>
        <li
          v-for="(item, index) in seckillInfo"
          :key="item.id"
          :class="index === seckillIndex ? 'time_start' : ''"
          @click="seckillIndex = index"
        >
          <span>{{ item.time | day }}</span>
          <h3>{{ item.seckillStartTime | time }}</h3>
          <b>
            {{
              item.seckillEnableState
                ? item.seckillEnableState === 1
                  ? "火热抢购中"
                  : "已结束"
                : "即将开始"
            }}
          </b>
        </li>
      </ul>
    </div> -->
    <div class="seckill_content">
      <div class="end_countdown">
        <p v-if="seckillInfo[seckillIndex].seckillEnableState !== 2">
          距离本场{{
            seckillInfo[seckillIndex].seckillEnableState ? "结束" : "开始"
          }}
          <van-count-down
            :time="
              seckillInfo[seckillIndex].seckillEnableState
                ? seckillInfo[seckillIndex].seckillEndTime - new Date()
                : seckillInfo[seckillIndex].seckillStartTime - new Date()
            "
            @finish="finish"
          >
            <template #default="timeData">
              <span class="block">{{
                timeData.days
                  ? timeData.hours + timeData.days * 24
                  : timeData.hours < 10
                  ? "0" + timeData.hours
                  : timeData.hours
              }}</span>
              <span class="colon">:</span>
              <span class="block">{{
                timeData.minutes < 10
                  ? "0" + timeData.minutes
                  : timeData.minutes
              }}</span>
              <span class="colon">:</span>
              <span class="block">{{
                timeData.seconds < 10
                  ? "0" + timeData.seconds
                  : timeData.seconds
              }}</span>
            </template>
          </van-count-down>
        </p>
        <p>秒杀结果以支付时间为准，抢完即止，结束后恢复原售价</p>
      </div>
      <div
        class="goods_box"
        v-for="(item, i) in goodsLsit"
        :key="i"
        @click="
          seckillInfo[seckillIndex].seckillEnableState !== 2
            ? $router.push({
                path: 'goodsDetails',
                query: {
                  commId: item.commId,
                },
              })
            : ''
        "
      >
        <img v-lazy="item.filePath" />
        <div class="details">
          <p class="name">{{ item.commodityName }}</p>
          <div class="inventory">
            <div
              class="progress_bar"
              :style="{
                '--barWidth':
                  Math.round((item.restAccount / item.inventoryCount) * 100) +
                  '%',
              }"
            >
              <span
                >库存{{
                  Math.round((item.restAccount / item.inventoryCount) * 100)
                }}%</span
              >
            </div>
            <span>限购{{ item.buyCount }}件</span>
          </div>
          <span class="price_difference"
            >立省{{
              Math.round((item.retailPrice - item.retailSeckillPrice) * 100) /
              100
            }}元</span
          >
          <div class="price">
            <div class="left">
              <p>￥{{ item.retailPrice.toFixed(2) }}</p>
              <p><span>￥</span>{{ item.retailSeckillPrice.toFixed(2) }}</p>
            </div>
            <!-- seckillInfo[seckillIndex].seckillEnableState === 1 &&
                      seckillInfo[seckillIndex].restAccount === 0 -->
            <button
              :style="
                seckillInfo[seckillIndex].seckillEnableState === 0
                  ? 'background:#F9635C'
                  : seckillInfo[seckillIndex].seckillEnableState === 2 ||
                    (seckillInfo[seckillIndex].seckillEnableState === 1 &&
                      item.restAccount === 0)
                  ? 'background:#C9C9C9'
                  : ''
              "
            >
              {{
                seckillInfo[seckillIndex].seckillEnableState
                  ? seckillInfo[seckillIndex].seckillEnableState === 1
                    ? item.restAccount
                      ? "立即抢购"
                      : "已售罄"
                    : "已结束"
                  : "未开始"
              }}
            </button>
          </div>
        </div>
      </div>
      <van-empty description="暂无商品" v-show="!goodsLsit.length" />
    </div>
    <functionButton />
  </div>
</template>

<script>
import { WechatSharing } from "@/utils/sdk";
export default {
  name: "seckill",
  data() {
    return {
      seckillInfo: [],
      seckillIndex: 0,
      goodsLsit: [],
      loading: false,
      // timer: {
      //   i: 0,
      //   startTime: new Date(),
      //   endTime: new Date(),
      // },
    };
  },
  filters: {
    time(val) {
      let date = new Date(val);
      let h = date.getHours(); // 时
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes(); // 分
      m = m < 10 ? "0" + m : m;

      return h + ":" + m;
    },
    day(val) {
      let date = new Date(val);
      let MM = date.getMonth() + 1; // 月
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate(); // 日
      d = d < 10 ? "0" + d : d;
      return MM + "/" + d;
    },
  },
  created() {
    if (this.$route.query.id) {
      // if (this.$route.query.id && !sessionStorage.getItem("userUuid")) {
      sessionStorage.setItem("userUuid", this.$route.query.id);
      // }
    } else {
      this.$router.replace({
        path: "seckill",
        query: {
          id: sessionStorage.getItem("userUuid")
            ? sessionStorage.getItem("userUuid")
            : undefined,
        },
      });
    }

    let shareInfo = {
      title: "正品汇商城，限时秒杀活动。",
      desc: "平台补贴，限时限量，抢完即止，快来抢购吧~",
      imgUrl: require("@/assets/images/logo.png"),
      link: window.location.hash,
    };
    WechatSharing(shareInfo);

    this.querySecKillInfoListByNow(true);
  },

  methods: {
    //查询秒杀场次
    async querySecKillInfoListByNow() {
      const data = await this.$API.homePage.querySecKillInfoListByNow({
        object: {
          day: 1,
        },
        pageNum: 0,
        pageSize: 0,
      });
      const data2 = await this.$API.homePage.querySecKillInfoListByNow({
        object: {
          day: 2,
        },
        pageNum: 0,
        pageSize: 0,
      });
      if (!data.data.data || !data2.data.data) return;
      if (data.data.data.length) {
        data.data.data.forEach((list) => {
          this.seckillInfo.push(list);
        });
      }
      if (data2.data.data.length) {
        data2.data.data.forEach((list) => {
          this.seckillInfo.push(list);
        });
      }
      this.seckillIndex = this.seckillInfo.findIndex((item) => {
        return item.seckillEnableState === 1 || item.seckillEnableState === 0;
      });
      if (this.seckillIndex < 0)
        this.seckillIndex = this.seckillInfo.length - 1;
      // console.log(formatDate(this.seckillInfo.seckillStartTime) + " 开始");
      // console.log(formatDate(this.seckillInfo.seckillEndTime) + " 结束");
      if (!this.seckillInfo.length) {
        // this.Toast.clear();
        this.Toast.fail("秒杀活动已结束");
        this.$router.replace("/");
        return;
      }
      this.getseckillList();
      // this.tabChange();
    },
    //秒杀商品列表
    async getseckillList() {
      this.goodsLsit = [];
      this.Toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true,
      });
      await this.$API.homePage
        .querySecKillCommodityList({
          object: {
            seckillInfoId: this.seckillInfo[this.seckillIndex].id,
          },
          pageNum: 0,
          pageSize: 0,
        })
        .then((res) => {
          this.goodsLsit = res.data.data;
          this.goodsLsit.forEach((goods) => {
            let img = goods.filePath.split(",");
            if (img[0].endsWith(".mp4")) {
              goods.filePath = img[1];
            } else {
              goods.filePath = img[0];
            }
          });
          this.Toast.clear();
        })
        .catch((e) => {
          console.log(e);
          this.Toast.clear();
        });

      // this.$set(this.seckillInfo[this.seckillIndex], "list", data.data.data);
    },
    tabChange(i) {
      // if (!this.seckillInfo[this.seckillIndex].list)
      this.getseckillList();
    },
    //倒计时结束
    finish() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.seckillInfo = [];
        this.querySecKillInfoListByNow();
      }, 2000);
    },
  },
};
</script>

<style lang="scss" scoped>
#seckill {
  display: flex;
  flex-direction: column;
  height: 100%;
  ::v-deep .van-tabs__wrap {
    height: auto;
    padding-top: 60px;
    background: linear-gradient(180deg, #f91c12 0%, #fc3b33 100%);
    .van-tabs__nav {
      padding-bottom: 0;
      padding-left: 15px;
      .van-tab {
        margin-right: 15px;
        padding: 12px 15px;
        text-align: center;
        min-width: 86px;
        flex: none;
      }
      .van-tab--active {
        background: #fff;
        border-radius: 20px 20px 0 20px;
      }
    }
    .van-tabs__line {
      display: none;
    }
  }
  .seckill_content {
    flex: 1;
    background: #f5f6fa;
    padding: 15px;
    .end_countdown {
      color: #333;
      p {
        line-height: 20px;
        .van-count-down {
          display: inline-block;
        }
        .colon {
          display: inline-block;
          margin: 0 3px;
          color: #f91c12;
          font-weight: bold;
        }
        .block {
          border-radius: 2px;
          display: inline-block;
          padding: 0 2px;
          color: #fff;
          background-color: #f91c12;
        }
      }
      p:last-child {
        font-size: 12px;
        color: #f91c12;
        margin: 5px 0;
      }
    }
    .goods_box {
      display: flex;
      background: #fff;
      border-radius: 10px;
      padding: 10px;
      margin-bottom: 10px;
      img {
        width: 132px;
        height: 132px;
        object-fit: contain;
        border-radius: 10px;
        margin-right: 12px;
      }
      .details {
        flex: 1;
        flex-shrink: 0;
        .name {
          font-weight: bold;
          color: #333333;
          margin-bottom: 12px;
        }
        .inventory {
          display: flex;
          margin-bottom: 10px;
          .progress_bar {
            width: 64px;
            height: 16px;
            background: #ff9570;
            border-radius: 8px;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            span {
              font-size: 12px;
              transform: scale(0.9);
              position: relative;
              top: -1px;
              z-index: 1;
            }
          }
          .progress_bar::after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: var(--barWidth);
            background: linear-gradient(180deg, #f99571 0%, #fb4e10 100%);
            border-radius: 8px;
          }
          > span {
            font-size: 12px;
            color: #999;
            margin-left: 8px;
          }
        }
        .price_difference {
          display: inline-block;
          border-radius: 4px;
          border: 1px solid #5cbd60;
          font-size: 12px;
          color: #5cbd60;
          padding: 2px 5px;
          margin-bottom: 10px;
        }
        .price {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          flex-wrap: wrap;
          .left {
            p:first-child {
              font-size: 12px;
              color: #999;
              text-decoration: line-through;
            }
            p:last-child {
              font-size: 18px;
              color: #f91c12;
              font-weight: bold;
              line-height: 25px;
              span {
                font-weight: normal;
                font-size: 12px;
              }
            }
          }
          button {
            width: 80px;
            height: 34px;
            background: linear-gradient(180deg, #f91c12 0%, #fb4e10 100%);
            border-radius: 17px;
            color: #fff;
            border: none;
          }
        }
      }
    }
  }
}
</style>
